<template lang="pug">
  Form(
    :title="$t('set_password.title')"
    @save="setPassword"
  )
    template(v-slot:fields)
      .d-flex.flex-row
        .form-item
          .form-item-label.d-flex.align-items-center {{ $t("set_password.password") }}
          .form-item-label.d-flex.align-items-center {{ $t("set_password.password_confirmation") }}
        .form-item
          .form-item-input.d-flex.align-items-center
            AppInputPassword(v-model="password")
          .form-item-input.d-flex.align-items-center
            AppInputPassword(
              v-model="passwordConfirmation"
              name="password_confirmation"
            )
      BAlert.create-account-alert(
        :show="isAlertShown"
        variant="danger"
        dismissible
        @dismissed="dismissAlert"
      )
        li.alert-tip(v-for="error in errors") {{ error }}
    template(v-slot:link)
      RouterLink.log-in(:to="{ name: 'SignIn' }") {{ $t("sign_in.title") }}
</template>

<script>
  // mixins
  import withValidations from "@/mixins/withValidations"

  // misc
  import { api } from "@/config"
  import { isEmpty } from "lodash-es"
  import { AUTH_LAYOUT, MAIL_SUPPORT } from "@/config/constants"

  const validationsMixin = withValidations(({ required, minLength }) => ({
    password: {
      required,
      minLength: minLength(8),
      sameAs: (value, self) => value === self.passwordConfirmation
    },
    passwordConfirmation: {
      required,
      minLength: minLength(8),
      sameAs: (value, self) => value === self.password
    }
  }))

  export default {
    layout: AUTH_LAYOUT,

    components: {
      Form: () => import("@/components/Auth/Form"),
      AppInputPassword: () => import("@/components/elements/AppInputPassword")
    },

    mixins: [validationsMixin],

    data() {
      return {
        password: "",
        passwordConfirmation: "",
        token: this.$route.query.token,
        errors: [],
        isAlertShown: false
      }
    },

    methods: {
      showAlert() {
        if (!isEmpty(this.errors)) this.isAlertShown = true
      },

      setPassword() {
        this.dismissAlert()
        this.validatePasswords()

        if (!this.isValid) {
          return this.showAlert()
        }

        const params = {
          token: this.token,
          password: this.password,
          password_confirmation: this.passwordConfirmation
        }
        api
          .post("/accounts/users/set_password", params, { skipErrorToast: true })
          .then(() => {
            this.$store.dispatch("FETCH_CURRENT_USER")
            this.$router.push({ name: "InventoryManagement" })
          })
          .catch(({ response: { status, data } }) => {
            this.setResponseErrors(status, data)
            this.showAlert()
          })
      },

      setResponseErrors(status, data) {
        switch (status) {
          case 400:
            this.errors = [data.common]
            break
          case 404:
            this.errors = [this.$t("set_password.validations.cannot_create_user", { email: MAIL_SUPPORT })]
            break
          case 422:
            this.errors = Object.values(data.record).flat()
            break
        }
      },

      validatePasswords() {
        this.validateAttributes()
        const { password, passwordConfirmation } = this.$v

        if (!password.minLength || !passwordConfirmation.minLength) {
          this.errors.push(this.$t("set_password.validations.at_least_8_characters"))
        }
        if (!password.sameAs) {
          this.errors.push(this.$t("set_password.validations.equal_to_confirmation"))
        }
      },

      dismissAlert() {
        this.isAlertShown = false
        this.errors = []
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .form-item
    width: 100%
    display: flex
    flex-direction: column
    margin-top: 20px

    &-label
      height: 50px
      white-space: nowrap
    &-input
      height: 50px

      input
        border: 1px solid $default-purple

  .alert-tip
    font-size: 0.8rem
</style>
